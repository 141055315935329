import {
  AtributosControllerApi, MultimediaControllerApi, ProductoAtributosControllerApi, ProductoMultimediaControllerApi, ProductoGeneralControllerApi, ProductosControllerApi,
  EmpresasControllerApi, RolesControllerApi, PermisosControllerApi, SituacionesControllerApi, MarcaControllerApi, FamiliasControllerApi, CategoriasControllerApi,
  GruposAtributosControllerApi, UsuariosControllerApi, ProductoCaracteristicasControllerApi, TraduccionesControllerApi, TablasControllerApi, ProductoIconosControllerApi, PlantillaControllerApi, IconosControllerApi,
  SustitutosControllerApi, EnsambladosControllerApi, RepuestosControllerApi, IdiomasControllerApi, ProductoDatosCatalogoControllerApi, VistaMuestraFotosOptimizadasControllerApi, settings
} from '@api/backend'
import { displaySuccessMsg, devuelveBasePath } from '@src/utility/Utils'
import Swal from "sweetalert2"
const prefix = 'integraciones'

export const GET_INTEGRACIONES = `${prefix}/GET_DATA`
export const GET_INTEGRACION = `${prefix}/GET`
export const ADD_INTEGRACION = `${prefix}/ADD`
export const UPDATE_INTEGRACION = `${prefix}/UPDATE`
export const DELETE_INTEGRACION = `${prefix}/DELETE`

const apiIntegraciones = new PlantillaControllerApi(settings)
const apiTablas = new TablasControllerApi(settings)
const apiAtt = new AtributosControllerApi(settings)
const apiProd = new ProductosControllerApi(settings)
const apiPmul = new ProductoMultimediaControllerApi(settings)
const apiPatt = new ProductoAtributosControllerApi(settings)
const apiPgen = new ProductoGeneralControllerApi(settings)
const apiEmp = new EmpresasControllerApi(settings)
const apiRol = new RolesControllerApi(settings)
const apiPerm = new PermisosControllerApi(settings)
const apiSit = new SituacionesControllerApi(settings)
// const apiFam = new FamiliasControllerApi(settings)
const apiMarca = new MarcaControllerApi(settings)
const apiCat = new CategoriasControllerApi(settings)
const apiUsu = new UsuariosControllerApi(settings)
const apiGAtt = new GruposAtributosControllerApi(settings)
const apiMult = new MultimediaControllerApi(settings)
const apiIconos = new IconosControllerApi(settings)
const apiIdiomas = new IdiomasControllerApi(settings)
const apiPico = new ProductoIconosControllerApi(settings)
const apiTraducciones = new TraduccionesControllerApi(settings)
const apiPcaracteristicas = new ProductoCaracteristicasControllerApi(settings)
const apiSustitutos = new SustitutosControllerApi(settings)
const apiEnsamblados = new EnsambladosControllerApi(settings)
const apiRepuestos = new RepuestosControllerApi(settings)
const apiProductoDatosCatalogo = new ProductoDatosCatalogoControllerApi(settings)
const apiVistaMuestraFotosOptimizada = new VistaMuestraFotosOptimizadasControllerApi(settings)

// ** Get all Data

export const getData = params => {
  return async (dispatch) => {
    try {
      const { page = 1, perPage = 10, sortBy = "", searchNombre = "", search = "", grupoCategorias } = params

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          ['nombre']: { like: `%${searchNombre}%`, options: 'i' }
        }
      }

      /* Comentado por innecesario ya que no se filtra por categorías
      //
      //Si existe el padre modifico el filtro para que añada esa condición
      //
      if (grupoCategorias) {
        filter.where['padre'] = {eq: `${grupoCategorias}`}
      }
      */

      const { data: dataintegracions } = await apiIntegraciones.plantillaControllerFind(JSON.stringify(filter))
      const { data: dataintegracionsCount } = await apiIntegraciones.plantillaControllerCount(JSON.stringify(filter.where))

      //const {data: dataintegracions} = await apiIntegraciones.plantillaControllerFind()
      //const {data: dataintegracionsCount} = await apiIntegraciones.plantillaControllerCount()
      const { data: productos } = await apiProd.productosControllerFind()
      const { data: productoGeneral } = await apiPgen.productoGeneralControllerFind()
      const { data: dataCategorias } = await apiCat.categoriasControllerFind()
      const { data: dataTablas } = await apiTablas.tablasControllerFind()
      dispatch({
        type: GET_INTEGRACIONES,
        integraciones: dataintegracions,
        allData: productos,
        categorias: dataCategorias,
        general: productoGeneral,
        tablas: dataTablas,
        total: dataintegracionsCount.count || 0
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addIntegracion = (integracion, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiIntegraciones.plantillaControllerCreate(integracion)
      displaySuccessMsg(intl.formatMessage({ id: 'Integracion creada' }))
      dispatch({
        type: ADD_INTEGRACION,
        data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

function validarFormatoDeFecha(fechaString) {

  const formatoCorrectoPatron = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  const tipoDeFechaPatron1 = /^(\d{2})\/(\d{2})\/(\d{4})$/  //-> patron para fechas (01/01/2020)
  const tipoDeFechaPatron2 = /^(\d{4})\/(\d{2})\/(\d{2})$/  //-> patron para fechas (2020/01/01)
  const tipoDeFechaPatron3 = /^(\d{2})-(\d{2})-(\d{4})$/    //-> patron para fechas (01-01-2020)
  const tipoDeFechaPatron4 = /^(\d{4})-(\d{2})-(\d{2})$/    //-> patron para fechas (2020-01-01)

  if (formatoCorrectoPatron.test(fechaString)) {
    return fechaString
  }

  if (tipoDeFechaPatron1.test(fechaString)) {
    const arrayFecha1 = fechaString.split('/')
    const patronDeFechaCorrecta1 = `${arrayFecha1[2]}-${arrayFecha1[1]}-${arrayFecha1[0]}T00:00:00.000Z`
    return patronDeFechaCorrecta1
  }

  if (tipoDeFechaPatron2.test(fechaString)) {
    const arrayFecha2 = fechaString.split('/')
    const patronDeFechaCorrecta2 = `${arrayFecha2[0]}-${arrayFecha2[1]}-${arrayFecha2[2]}T00:00:00.000Z`
    return patronDeFechaCorrecta2
  }

  if (tipoDeFechaPatron3.test(fechaString)) {
    const arrayFecha3 = fechaString.split('-')
    const patronDeFechaCorrecta3 = `${arrayFecha3[2]}-${arrayFecha3[1]}-${arrayFecha3[0]}T00:00:00.000Z`
    return patronDeFechaCorrecta3
  }

  if (tipoDeFechaPatron4.test(fechaString)) {
    const arrayFecha4 = fechaString.split('-')
    const patronDeFechaCorrecta4 = `${arrayFecha4[0]}-${arrayFecha4[1]}-${arrayFecha4[2]}T00:00:00.000Z`
    return patronDeFechaCorrecta4
  }

  // Retornar null si la fecha no es válida
  return null
}

export const insertarLinea = (objeto, tabla) => {
  return async (dispatch) => {
    try {
      switch (tabla) {
        case 'atributos':
          objeto.codigo = parseInt(objeto.codigo)
          await apiAtt.atributosControllerCreate(objeto)
          break
        case 'multimedia': await apiMult.multimediaControllerCreate(objeto)
          break
        case 'categorias': await apiCat.categoriasControllerCreate(objeto)
          break
        // case 'familias': await apiFam.familiasControllerCreate(objeto)
        //   break
        case 'permisos': await apiPerm.permisosControllerCreate(objeto)
          break
        case 'empresas': await apiEmp.empresasControllerCreate(objeto)
          break
        case 'grupos_atributos': await apiGAtt.gruposAtributosControllerCreate(objeto)
          break
        case 'marca': await apiMarca.marcaControllerCreate(objeto)
          break
        case 'situaciones': await apiSit.situacionesControllerCreate(objeto)
          break
        case 'roles': await apiRol.rolesControllerCreate(objeto)
          break
        case 'usuarios': await apiUsu.usuariosControllerCreate(objeto)
          break
        case 'producto_multimedia': await apiPmul.productoMultimediaControllerCreate(objeto)
          break
        case 'producto_general':
          //objeto.productoId = parseInt(objeto.productoId)
          await apiPgen.productoGeneralControllerCreate(objeto)
          break
        case 'producto_atributos': await apiPatt.productoAtributosControllerCreate(objeto)
          break
        case 'productos':
          const fechaYHoraActual = new Date().toISOString()
          objeto.fechaModificacion = fechaYHoraActual
          const { data: productoCreado } = await apiProd.productosControllerCreate(objeto)
          const prodGeneralObjeto = {
            productoId: productoCreado.id,
            sku: objeto.nombre
          }
          await apiPgen.productoGeneralControllerCreate(prodGeneralObjeto)
          break
        case 'iconos': await apiIconos.iconosControllerCreate(objeto)
          break
        case 'idiomas': await apiIdiomas.idiomasControllerCreate(objeto)
          break
        case 'producto_iconos': await apiPico.productoIconosControllerCreate(objeto)
          break
        case 'traducciones': await apiTraducciones.traduccionesControllerCreate(objeto)
          break
        case 'producto_caracteristicas': await apiPcaracteristicas.productoCaracteristicasControllerCreate(objeto)
          break
        case 'sustitutos':
          if (objeto.intercambiable.toLowerCase() === 'si' || objeto.intercambiable.toLowerCase() === 'sí' || objeto.intercambiable.toLowerCase() === 's' || objeto.intercambiable.toLowerCase() === 'true') {
            objeto.intercambiable = 'S'
          }
          if (objeto.intercambiable.toLowerCase() === 'no' || objeto.intercambiable.toLowerCase() === 'n' || objeto.intercambiable.toLowerCase() === 'false') {
            objeto.intercambiable = 'N'
          }
          objeto.fechaInicio = validarFormatoDeFecha(objeto.fechaInicio)
          objeto.fechaFin = validarFormatoDeFecha(objeto.fechaFin)
          const fechaYHoraActualSustituto = new Date().toISOString()
          objeto.fechaModificacion = fechaYHoraActualSustituto
          await apiSustitutos.sustitutosControllerCreate(objeto)
          break
        case 'ensamblados':
          objeto.cantidad = parseInt(objeto.cantidad)
          const fechaYHoraActualEnsamblado = new Date().toISOString()
          objeto.fechaModificacion = fechaYHoraActualEnsamblado
          await apiEnsamblados.ensambladosControllerCreate(objeto)
          break
        case 'repuestos':
          objeto.posicion = parseInt(objeto.posicion)
          objeto.fechaInicio = validarFormatoDeFecha(objeto.fechaInicio)
          objeto.fechaFin = validarFormatoDeFecha(objeto.fechaFin)
          const fechaYHoraActualRepuesto = new Date().toISOString()
          objeto.fechaModificacion = fechaYHoraActualRepuesto
          await apiRepuestos.repuestosControllerCreate(objeto)
          break
        case 'producto_datos_catalogo':
            await apiProductoDatosCatalogo.productoDatosCatalogoControllerCreate(objeto)
            break
        default:
          // console.error('problema con el nombre de la tabla')
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Problema con el nombre de la tabla',
            showCloseButton: true
          })
      }
    } catch (err) {
      console.error(err.message)
      const error = localStorage.getItem('error')
      // const errorString = "ER_DUP_ENTRY: Duplicate entry '1-2' for key 'unique_producto_atributo'"

      // Extraer el código de error
      let codigoError = ""
      let codigoErrorInvalido = ""
      if (error) {
        codigoError = error.match(/^[A-Z_]+/)[0]
        codigoErrorInvalido = error.split(".")[0].trim()
      }
      if (codigoError == "ER_DUP_ENTRY") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Atributo duplicado en uno o varios productos'
        })
      } else if (codigoErrorInvalido == "The request body is invalid") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Formato del documento incorrecto'
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error'
        })
      }
      return "error"
    }
  }
}

export const actualizarLinea = (id, objeto, tabla) => {
  return async () => {
    try {
      switch (tabla) {
        case 'atributos':
          objeto.codigo = parseInt(objeto.codigo)
          await apiAtt.atributosControllerUpdateById(id, objeto)
          break
        case 'multimedia': await apiMult.multimediaControllerUpdateById(id, objeto)
          break
        case 'categorias': await apiCat.categoriasControllerUpdateById(id, objeto)
          break
        // case 'familias': await apiFam.familiasControllerUpdateById(id, objeto)
        //   break
        case 'productos':
          await apiProd.productosControllerUpdateById(id, objeto)
          break
        case 'permisos': await apiPerm.permisosControllerUpdateById(id, objeto)
          break
        case 'empresas': await apiEmp.empresasControllerUpdateById(id, objeto)
          break
        case 'grupos_atributos': await apiGAtt.gruposAtributosControllerUpdateById(id, objeto)
          break
        case 'marca': await apiMarca.marcaControllerUpdateById(id, objeto)
          break
        case 'situaciones': await apiSit.situacionesControllerUpdateById(id, objeto)
          break
        case 'roles': await apiRol.rolesControllerUpdateById(id, objeto)
          break
        case 'usuarios': await apiUsu.usuariosControllerUpdateById(id, objeto)
          break
        case 'producto_multimedia': await apiPmul.productoMultimediaControllerUpdateById(id, objeto)
          break
        case 'producto_general':
          //objeto.productoId = parseInt(objeto.productoId)
          await apiPgen.productoGeneralControllerUpdateById(id, objeto)
          break
        case 'producto_atributos':
          await apiPatt.productoAtributosControllerUpdateById(id, objeto)
          break
        case 'iconos': await apiIconos.iconosControllerUpdateById(id, objeto)
          break
        case 'idiomas': await apiIdiomas.idiomasControllerUpdateById(id, objeto)
          break
        case 'producto_iconos': await apiPico.productoIconosControllerUpdateById(id, objeto)
          break
        case 'traducciones': await apiTraducciones.traduccionesControllerUpdateById(id, objeto)
          break
        case 'producto_caracteristicas': await apiPico.productoIconosControllerUpdateById(objeto)
          break
        case 'sustitutos':
          if (objeto.intercambiable.toLowerCase() === 'si' || objeto.intercambiable.toLowerCase() === 'sí' || objeto.intercambiable.toLowerCase() === 's' || objeto.intercambiable.toLowerCase() === 'true') {
            objeto.intercambiable = 'S'
          }
          if (objeto.intercambiable.toLowerCase() === 'no' || objeto.intercambiable.toLowerCase() === 'n' || objeto.intercambiable.toLowerCase() === 'false') {
            objeto.intercambiable = 'N'
          }
          objeto.fechaInicio = validarFormatoDeFecha(objeto.fechaInicio)
          objeto.fechaFin = validarFormatoDeFecha(objeto.fechaFin)
          await apiSustitutos.sustitutosControllerUpdateById(id, objeto)
          break
        case 'ensamblados':
          objeto.cantidad = parseInt(objeto.cantidad)
          await apiEnsamblados.ensambladosControllerUpdateById(id, objeto)
          break
        case 'repuestos':
          objeto.posicion = parseInt(objeto.posicion)
          objeto.fechaInicio = validarFormatoDeFecha(objeto.fechaInicio)
          objeto.fechaFin = validarFormatoDeFecha(objeto.fechaFin)
          await apiRepuestos.repuestosControllerUpdateById(id, objeto)
          break
        case 'producto_datos_catalogo':
          await apiProductoDatosCatalogo.productoDatosCatalogoControllerUpdateById(id, objeto)
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Problema con el nombre de la tabla',
            showCloseButton: true
          })
          return true
      }
    } catch (err) {
      console.log(err)
      const error = localStorage.getItem('error')
      let codigoError = ""
      if (error) {
        codigoError = error.split(":")[0].trim()
      }
      if (codigoError == 'Entity not found') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Uno o varios atributos no existen (${error})`
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error
        })
      }
      return err
    }
  }
}

export const updateIntegracion = (id, integracion, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiIntegraciones.plantillaControllerUpdateById(id, integracion)
      displaySuccessMsg(intl.formatMessage({ id: 'Integracion actualizada' }))
      dispatch({
        type: UPDATE_INTEGRACION,
        data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteIntegracion = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await apiIntegraciones.plantillaControllerDeleteById(id)
      location.reload()
      dispatch({
        type: DELETE_INTEGRACION,
        data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getIntegracion = (integracion) => {
  return async (dispatch) => {
    try {
      const { data: dataIntegracion } = await apiIntegraciones.plantillaControllerFindById(integracion)
      dispatch({
        type: GET_INTEGRACION,
        selectedIntegracion: dataIntegracion
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

const csvmaker = function (data, cabecera) {
  // Dividir la cadena en un arreglo de encabezados
  const headers = cabecera.split(',')
  // Encontrar el índice de la columna "Familias"
  const familiaIndex = headers.indexOf('Familias')
  if (familiaIndex !== -1) {
    // Eliminar la columna "Familias" del arreglo de encabezados
    headers.splice(familiaIndex, 1)
    // Crear una nueva cadena de encabezados sin la columna "Familias"
    cabecera = headers.join(',')
  }
  const cabeceraConComas = cabecera.replaceAll(',', ';')
  const csvRows = ['id;'.concat(cabeceraConComas)]
  let valuesStr = '', valueCampo, campo
  const campos = cabecera.split(",")

  Object.values(data).forEach(value => {
    valuesStr = value.id.toString()
    campos.forEach(el => {
      valueCampo = el === 'GLN' ? el : el[0].toLowerCase().concat(el.substring(1, el.length))
      campo = value[valueCampo]
      if (campo != null) {
        campo = campo.toString().replaceAll('\n', '').replaceAll(';', '')
      } else {
        campo = ''
      }
      valuesStr = valuesStr.concat(";").concat(campo)
    })
    csvRows.push(valuesStr)
  })
  return csvRows.join('\n')
}

const csvmakerImagenes = function (data) {
  //
  // Definición de variables
  //
  let valuesStr = '', campo
  //
  //Como los nombres de las columnas pueden variar, recorremos los datos para obtener los nombres 
  //
  const getColumnNames = (data) => {
    const columnNames = new Set()
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        columnNames.add(key)
      })
    })
    return Array.from(columnNames)
  }
  const campos = getColumnNames(data)
  //
  //Montamos la cabecera
  //
  const csvRows = [campos.join(";")]
  //
  //Bucle para recorrer todos los datos del csv
  //
  Object.values(data).forEach(value => {
    //
    //para cada línea del csv, recorremos los campos de la cabecera
    //
    campos.forEach(el => {
      //
      //Obtenemos el valor del campo
      //
      campo = value[el[0].toLowerCase().concat(el.substring(1, el.length))]
      //
      //Si existe el valor del campo y estamos tratando con un fichero le ponemos la ruta completa
      //
      if ((campo) && (el.indexOf('fichero') === 0)) {
        campo = `${devuelveBasePath()}/${campo}`
      }
      //
      //Si el campo valor del campo existe, lo formateo para el csv, sino lo dejo vacío
      //
      if (campo != null) {
        campo = campo.toString().replaceAll('\n', '').replaceAll(';', '')
      } else {
        campo = ''
      }
      //
      //Monto una cadena con todos los valores de la linea del csv
      //
      valuesStr = (valuesStr) ? valuesStr.concat(";").concat(campo) : valuesStr.concat(campo)
    })
    //
    //Añado la línea al csv y vació la variable valuesStr para la siguiente línea
    //
    csvRows.push(valuesStr)
    valuesStr = ''
  })
  //
  //Devuelvo el csv
  //
  return csvRows.join('\n')
}

const descargaFichero = function (data, tabla) {
  const csvContent = `\uFEFF${data}` //-> Para corregir el error de los caracteres con tílde
  const blob = new Blob([csvContent], { type: 'text/csv; charset=utf-8' })
  const url = window.URL.createObjectURL(blob)
  localStorage.setItem("csv", url)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', tabla.concat('_PIM.csv'))
  a.click()
}

export const descargarCsv = (tabla, cabecera) => {
  return async () => {
    Swal.fire({
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    try {
      switch (tabla) {
        case 'atributos': await apiAtt.atributosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'multimedia': await apiMult.multimediaControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'categorias': await apiCat.categoriasControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        // case 'familias': await apiFam.familiasControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
        //   break
        case 'productos': await apiProd.productosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'permisos': await apiPerm.permisosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'empresas': await apiEmp.empresasControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'grupos_atributos': await apiGAtt.gruposAtributosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'marca': await apiMarca.marcaControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'situaciones': await apiSit.situacionesControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'roles': await apiRol.rolesControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'usuarios': await apiUsu.usuariosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'producto_multimedia': await apiPmul.productoMultimediaControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'producto_general': await apiPgen.productoGeneralControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'producto_atributos': await apiPatt.productoAtributosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'iconos': await apiIconos.iconosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'idiomas': await apiIdiomas.idiomasControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'producto_iconos': await apiPico.productoIconosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'traducciones': await apiTraducciones.traduccionesControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'producto_caracteristicas': await apiPcaracteristicas.productoCaracteristicasControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'sustitutos': await apiSustitutos.sustitutosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'ensamblados': await apiEnsamblados.ensambladosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'repuestos': await apiRepuestos.repuestosControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
          break
        case 'producto_datos_catalogo': await apiProductoDatosCatalogo.productoDatosCatalogoControllerFind().then((response) => { descargaFichero(csvmaker(response.data, cabecera), tabla) })
        break
        case 'vista_muestra_fotos_optimizada': await apiVistaMuestraFotosOptimizada.vistaMuestraFotosOptimizadasControllerFind().then((response) => { descargaFichero(csvmakerImagenes(response.data), tabla) })
          break
        default:
          // console.error('problema con el nombre de la tabla')
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Problema con el nombre de la tabla',
            showCloseButton: true
          })
      }
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initintegracion = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_INTEGRACION,
      selectedCompany: null
    })
  }
}

/////////////////////////////////////
// I N T E G A C I Ó N - M I X T A // 
////////////////////////////////////
export const obtenerTodasLasSituaciones = () => {
  return async () => {
    try {
      return await apiSit.situacionesControllerFind().then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerTodosLos_SKU_Existentes = () => {
  return async () => {
    try {
      return await apiPgen.productoGeneralControllerFind().then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarProductoPorSKU = (sku_a_buscar) => {
  return async () => {
    try {
      const filter =  {
        where: {
          ['sku']: sku_a_buscar
        }
      }
      return await apiPgen.productoGeneralControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const actualizarProductoGeneralPorId = (id, productoGeneral) => {
  return async () => {
    try {
      return await apiPgen.productoGeneralControllerUpdateById(id, productoGeneral).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const actualizarProductoPorId = (id, producto) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      producto.fechaModificacion = fechaYHoraActual
      return await apiProd.productosControllerUpdateById(id, producto).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const crearProducto = (nombreProducto, categoria, sincroConBC) => {
  return async () => {
    try {
        const fechaYHoraActual = new Date().toISOString()
        const objProducto = {
          empresaId: 1,
          nombre: nombreProducto,
          imagen: `multimedia/${nombreProducto}/1250x850_imagen_principal.png`,
          categorias: categoria,
          familias: "",
          sincronizarConBC: (sincroConBC.toLowerCase() === 'true' || sincroConBC.toLowerCase() === 'si' || sincroConBC.toLowerCase() === 's') ? 'S' : 'N',
          fechaCreacion: fechaYHoraActual,
          fechaModificacion: fechaYHoraActual
        }
        return await apiProd.productosControllerCreate(objProducto).then(response => {
          return response.data
        })    
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const crearProductoGeneral = (objNewProductoGeneral) => {
  return async () => {
    try {
        return await apiPgen.productoGeneralControllerCreate(objNewProductoGeneral).then(response => {
          return response.data
        })    
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const crearProductoAtributos = (objNewProductoAtrubutos) => {
  return async () => {
    try {
        return await apiPatt.productoAtributosControllerCreate(objNewProductoAtrubutos).then(response => {
          return response.data
        })    
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerIdDelAtributoVolumen = () => {
  return async () => {
    try {
      const filter =  {
        where: {
          ['nombre']: 'Volumen'
        }
      }
      return await apiAtt.atributosControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarRegistroEnProductoAtributos = (producto_id, atributo_id) => {
  return async () => {
    try {
      const filter =  {
        where: {
          ['productoId']: producto_id,
          ['atributoId']: atributo_id
        }
      }
      return await apiPatt.productoAtributosControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const actualizarProductoAtributoPorId = (id, productoAtributo) => {
  return async () => {
    try {
      return await apiPatt.productoAtributosControllerUpdateById(id, productoAtributo).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarSustitutoPorSKUPrincipal_Y_SKUSustituto = (sku_principal, sku_sustituto) => {
  return async () => {
    try {
      const filter =  {
        where: {
          ['skuPrincipal']: sku_principal,
          ['skuSustituto']: sku_sustituto
        }
      }
      return await apiSustitutos.sustitutosControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const actualizarSustitutoPorId = (id, sustituto) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      sustituto.fechaModificacion = fechaYHoraActual
      return await apiSustitutos.sustitutosControllerUpdateById(id, sustituto).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const crearSustituto = (objNewSustituto) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      objNewSustituto.fechaCreacion = fechaYHoraActual
      objNewSustituto.fechaModificacion = fechaYHoraActual
      return await apiSustitutos.sustitutosControllerCreate(objNewSustituto).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarRepuestoPorSKUPrincipal_Y_SKURepuesto = (sku_principal, sku_repuesto) => {
  return async () => {
    try {
      const filter =  {
        where: {
          ['skuPrincipal']: sku_principal,
          ['skuRepuesto']: sku_repuesto
        }
      }
      return await apiRepuestos.repuestosControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const actualizarRepuestoPorId = (id, repuesto) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      repuesto.fechaModificacion = fechaYHoraActual
      return await apiRepuestos.repuestosControllerUpdateById(id, repuesto).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const crearRepuesto = (objNewRepuesto) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      objNewRepuesto.fechaCreacion = fechaYHoraActual
      objNewRepuesto.fechaModificacion = fechaYHoraActual
      return await apiRepuestos.repuestosControllerCreate(objNewRepuesto).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarEnsambladoPorSKUPrincipal_Y_SKUEnsamblado = (sku_principal, sku_ensamblado) => {
  return async () => {
    try {
      const filter =  {
        where: {
          ['skuPrincipal']: sku_principal,
          ['skuEnsamblado']: sku_ensamblado
        }
      }
      return await apiEnsamblados.ensambladosControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const actualizarEnsambladoPorId = (id, ensamblado) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      ensamblado.fechaModificacion = fechaYHoraActual
      return await apiEnsamblados.ensambladosControllerUpdateById(id, ensamblado).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const crearEnsamblado = (objNewEnsamblado) => {
  return async () => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      objNewEnsamblado.fechaCreacion = fechaYHoraActual
      objNewEnsamblado.fechaModificacion = fechaYHoraActual
      return await apiEnsamblados.ensambladosControllerCreate(objNewEnsamblado).then(response => {
        return response.data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}